@import 'src/styles/vars.scss';

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; } 
}

@keyframes spin { 
  100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } 
}

#Examples-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(90deg, $primary-dark 0%, $primary 60%);
  min-height: 100vh;

  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: linear; 
}

.react-logo {
  width: 300px;
  height: 300px;
  animation: spin 30s linear infinite;
}

.page-headline { 
  font-family: "Lato","Helvetica","Arial",sans-serif;
  color: white;
  font-size: 4rem;
  letter-spacing: 0.05em;
  line-height: 4rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 6rem;
  width: 40%;
}

.page-sub-headline {
  font-family: "Lato","Helvetica","Arial",sans-serif;
  font-size: 2rem;
  color: white;
  margin-top: 0;
  margin-bottom: 4rem;
}