.style-guide-section { 
  display: flex;
  margin-bottom: 2.5rem;
}

.style-guide-column { 
  flex-direction: column;
  width: 22.5rem;
}

.style-guide-color-container { 
  display: flex;
  margin-bottom: 1.25rem;
  height: 2.25rem;
  align-items: center;

  .style-guide-rounded-color { 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9.375rem;
    height: 100%;
    border-radius: 4px;
    
    .style-guide-rounded-color-text {
      margin: 0;
      font-size: 0.875rem;
    }

    .style-guide-white { 
      color: $white;
    }
  }
  
  .style-guide-color-text-container {
    display: flex;
    align-items: center;
    width: 7.5rem;
    height: 100%;
    margin: 0 0 0 1.25rem;

    .style-guide-color-text { 
      margin: 0;
      font-size: 0.875rem;
    }
  }
}

#primary {
  background-color: $primary;
}
#primary-light {
  background-color: $primary-light;
}
#primary-dark {
  background-color: $primary-dark;
}

#secondary {
  background-color: $secondary;
}
#secondary-light {
  background-color: $secondary-light;
}
#secondary-dark {
  background-color: $secondary-dark;
}

#positive {
  background-color: $positive;
}
#positive-light {
  background-color: $positive-light;
}
#positive-dark {
  background-color: $positive-dark;
}

#negative {
  background-color: $negative;
}
#negative-light {
  background-color: $negative-light;
}
#negative-dark {
  background-color: $negative-dark;
}

#smoke {
  background-color: $smoke;
}
#smoke-light {
  background-color: $smoke-light;
}
#smoke-dark {
  background-color: $smoke-dark;
}

#steam {
  background-color: $steam;
}
#steam-light {
  background-color: $steam-light;
}
#steam-dark {
  background-color: $steam-dark;
}