/* Text styles */

.body-copy {
  font-family: $base-font-family;
  font-size: 0.875rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: $smoke-dark;
}
.body-link {
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: $primary;
}

/* Header styles */
.h1-heading, .alpha-text {
  font-family: $base-font-family;
  font-size: $h1-size;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: $smoke-dark;
}
.h2-heading, .bravo-text {
  font-family: $base-font-family;
  font-size: $h2-size;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: $smoke-dark;
}
.h3-heading, .charlie-text {
  font-family: $base-font-family;
  font-size: $h3-size;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $smoke-dark;
}
.h4-heading, .delta-text {
  font-family: $base-font-family;
  font-size: $h4-size;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $smoke-dark;
}
.h5-heading, .echo-text {
  font-family: $base-font-family;
  font-size: $h5-size;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $smoke-dark;
}
.h6-heading, .foxtrot-text {
  font-family: $base-font-family;
  font-size: $h6-size;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $smoke-dark;
}

.code {
  font-family: monospace;
  font-size: 0.75rem;
  background-color: $steam-light;
  padding: 0.25rem 0.5rem;
  display: block;
}
