.space-comfortable {
    margin: 1rem 0;
}

.button {
    background-color: $white;
    border: solid 1px $smoke-light;
    border-radius: 0.25rem;
    font-family: $base-font-family;
    font-size: 1rem;
    line-height: 1;
    padding: 0.875rem;
    text-align: center;
    &:hover, &:focus {
        background-color: $steam-light;
    }
    &:active {
        background-color: $steam;
    }
    &--mega {
        padding: 1.1875rem;
        width: 13rem;
    }
    
    &--medium {
        padding: 0.875rem;
        width: 11.25rem;
    }
    
    &--mini {
        padding: 0.5625rem;
        width: 9.5rem;
    }
    
    &--full-width {
        width: 100%;
    }
    
    &--primary {
        background-color: $primary;
        color: $white;
        &:hover, &:focus {
            background-color: $primary-light;
            border: solid 1px $primary;
            color: $smoke-dark;
        }
        &:active {
            background-color: $primary-dark;
        }
    }
    
    &--secondary {
        background-color: $secondary;
        color: $white;
        &:hover, &:focus {
            background-color: $secondary-light;
            border: solid 1px $secondary;
            color: $smoke-dark;
        }
        &:active {
            background-color: $secondary-dark;
        }
    }
    
    &--destructive {
        background-color: $negative;
        color: $white;
        &:hover, &:focus {
            background-color: $negative-light;
            border: solid 1px $negative;
            color: $smoke-dark;
        }
        &:active {
            background-color: $negative-dark;
        }
    }
    
    &--disabled {
        background-color: $steam-light;
        border: solid 1px $steam;
        color: $steam-dark;
        pointer-events: none;
    }
}