.style-guide-definition-container {
  display: flex;
  margin: 0 0 3.375rem 0;

  .style-guide-definition-text-container {
    width: 44.625rem;
    height: 12.375rem;
    margin: 1.25rem 6.5rem 0 0;

    .style-guide-definition-text {
      font-size: 0.875rem;
      line-height: 1.29;
      color: $smoke-dark;
      margin: 0;
    }
  }

  .style-guide-example-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 17.375rem;
    height: 9.5rem;
    margin: 1.25rem 0 0 0;

    .style-guide-example-label {
      font-size: 0.875rem;
      letter-spacing: -0.2px;
      color: #919eab;
      margin: 0 0 1rem 0;
    }

    .style-guide-example-text {
      font-size: 1.5rem;
      line-height: 1.29;
      text-align: center;
      color: $smoke-dark;
      margin: 0;
    }
  }
}

.style-guide-buttons-container {
  display: flex;
  margin: 1.25rem 0 3.75rem 0;

  .style-guide-button-column {
    display: flex;
    width: 13rem;
    margin: 0 1.875rem 0 0;
    flex-direction: column;

    .style-guide-button-container {
      margin: 0 0 1.25rem 0;
    }

    .style-guide-example-label {
      font-size: 0.875rem;
      letter-spacing: -0.2px;
      color: #919eab;
      margin: 0 0 1.25rem 0;
      text-align: center;
    }
  }

  .style-guide-text-column {
    display: flex;
    margin: 0 0 0 4.1875rem;
    flex-direction: column;
    justify-content: space-between;

    .style-guide-example-spacing {
      height: 2.25rem;
    }

    .style-guide-example-label-container {
      height: 3.5rem;
      margin: 0 0 1.25rem 0;
      display: flex;
      align-items: center;

      .style-guide-example-label {
        font-size: 0.875rem;
        letter-spacing: -0.2px;
        color: #919eab;
        margin: 0;
      }
    }
  }
}

.style-guide-sizes-links-container {
  display: flex;
  margin-bottom: 4rem;

  .style-guide-sizes-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 42.5rem;
    margin-right: 2.125rem;

    #button-sizes {
      width: 100%;
    }
    .style-guide-sizes-button-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1.25rem 0 0 0;
    }
  }

  .style-guide-links-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    #links {
      width: 26rem;
      margin-bottom: 2.3125rem;
      
    }
    #navlink-links { 
      width: 7.75rem;
    }
    .navlink-active { 
      color: $primary;
      font-size: 0.875rem;
    }
  }
}

.dark { 
  color: $smoke-dark;
}

.light { 
  color: $white;
}

.hover { 
  background-color: $steam-light;
}

.active { 
  background-color: $steam;
}

.hover-primary { 
  background-color: $primary-light;
}

.active-primary { 
  background-color: $primary-dark;
}

.hover-secondary { 
  background-color: $secondary-light;
}

.active-secondary { 
  background-color: $secondary-dark;
}

.hover-destructive { 
  background-color: $negative-light;
}

.active-destructive { 
  background-color: $negative-dark;
}