.side-bar-container {
  width: 12.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $steam-light;
}

.style-guide-content-container {
  margin: 5.3%;
  margin-top: 3.375rem;
  flex: 1;
}

.side-bar-logo {
  display: flex;
  overflow: hidden;
  width: 130px;
  height: 60px;
  margin: 15px 25px 15px 25px;

  img { 
    object-fit: contain;
    min-width: 100%;
    min-height: 100%;
  }
}

.side-bar-item {
  width: 100%;
  height: 36px;
  margin-bottom: 12px;

  .side-bar-link {
    display: block;
    text-decoration: none;
    color: $smoke-dark;
    padding: 0.625rem 0 0.625rem 1.875rem;

  }
  .selected {
    background-color: $positive-light;
    font-weight: bold;
  }

  .navlink-active {
    background-color: none;
  }
}
