
.style-guide-container {
  display: flex;
}

.style-guide-header {
  font-family: $base-font-family;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
  text-transform: uppercase;
  color: $smoke-light;
}

.style-guide-header__underline {
  border-bottom: 1px solid $steam;
  padding-bottom: 0.6875rem;
}

.style-guide-typeface {
  display: flex;
  padding-top: 0.9375rem;
  .h4-heading {
    margin-right: 6%;
  }
  .h4-heading:last-child {
    margin-right: 0;
  }
  .h4-heading:nth-child(1) {
    width: 24%;
  }
  .h4-heading:nth-child(2) {
    width: 50%;
  }
}

.style-guide-typeface-table {
  width: 100%;
  margin-top: 5.4375rem;
  thead {
    td {
      border-bottom: 1px solid $steam;
      padding-bottom: 0.6875rem;
      height: auto;
    }
  }
  td {
    width: 15%;
    height: 5.875rem;
    border-bottom: 1px solid $steam;
  }
  td:first-child {
    width: 40%;
  }
}

// home styles
.style-guide-home-hero {
  width: 100%;
  background-image: linear-gradient(to bottom, $positive, $primary);
  padding: 7.625rem 0 4.313rem 0;
}

.style-guide-home-secondary {
  margin-bottom: 4.625rem;
  div:first-child {
    width: 75%;
    margin-right: 4rem;
  }
  div:last-child {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}

.style-guide-home-tertiary {
  margin-bottom: 4.625rem;
  div:first-child {
    margin-right: 3.938rem;
  }
  div:last-child {
    margin-left: 3.938rem;
  }
  h2 {
    margin-bottom: .8rem;
    @media only screen and (max-width : 1200px) {
      padding: 0 4rem;
    }
  }
}

.style-guide-home-quaternary {
  margin-bottom: 4.625rem;
  h2 {
    margin-bottom: 1.625rem;
  }
  li {
    padding-right: 5rem;
  }
}

.style-guide-image-container {
  background: $steam-light;
  height: 7.063rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 7.188rem;
}

.hero-secondary-header {
  margin: 1.313rem 0 2.313rem 0;
}



