// global
@import 'vars';
@import 'typography';

// components
@import '../components/Button/button';
@import '../components/Examples/style';

// styleguide
@import '../components/Styleguide/Sidebar/sidebar';
@import '../components/Styleguide/Buttons/buttons';
@import '../components/Styleguide/Color/color';
@import '../components/Styleguide/style';

html {
  text-align: $dir-default;
  color: $base-color;
  font-size: $base-font-size;
}

body {
  font-family: $base-font-family;
  background-color: $white;
}


html, body {
  min-height: 100%;
  margin: 0;
}

table {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  border-color: inherit;
  vertical-align: inherit;
  text-align: left;
  font-weight: inherit;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
}

thead, tbody {
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

div#app { 
  width: 100%;
  height: 100%;
}

.space-comfortable {
  margin: $base-spacing-unit 0;
}

.style-guide-container {
  width: 100%;
  height: 100%;
  background-color: $white;
}

.img-responsive {
  height: auto;
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-reverse {
  color: $white;
}

//layout
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.list-no-style {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-number-bullets {
  counter-reset: number-bullets-counter;
  li {
    counter-increment: number-bullets-counter;
    position: relative;
    padding-left: 3.625rem;
    flex: 1;
  }
  li::before {
    content: counter(number-bullets-counter);
    position: absolute;
    width: 2.625rem;
    height: 2.625rem;
    background: $steam-light;
    font-size: 1.125rem;
    color: $smoke-dark;
    border-radius: 50%;
    text-align: center;
    left: 0;
    line-height: 2.2;
    font-weight: bold;
  }
}
