/*------------------------------------*\
    COLORS
\*------------------------------------*/

$primary: #007ace;
$primary-light: #b4e0fa;
$primary-dark: #084e8a;
$positive: #50b83c;
$positive-light: #bbe5b3;
$positive-dark: #108043;
$smoke: #454f5b;
$smoke-light: #637381;
$smoke-dark: #212b36;
$secondary: #5c6ac4;
$secondary-light: #b3bcf5;
$secondary-dark: #202e78;
$negative: #ed6347;
$negative-light: #fdac9a;
$negative-dark: #bf0711;
$steam: #dfe3e8;
$steam-light: #f4f6f8;
$steam-dark: #c4cdd5;
$white: #ffffff;


/*------------------------------------*\
    $BASE
\*------------------------------------*/

$base-color: $smoke-dark;
$base-font-family:'Roboto', Helvetica, sans-serif;
$base-font-size: 16px;
$base-line-height: 1.29;
$base-spacing-unit: 1rem;
$base-radius: 2px;

$default_font_weight: 400;

/*------------------------------------*\
    HEADINGS
\*------------------------------------*/

$heading-font-family: $base-font-family;

    /**
     * Mobile Sizes
     */

    $h1-size: 3.375rem;
    $h2-size: 3rem;
    $h3-size: 2rem;
    $h4-size: 1.5rem;
    $h5-size: 1.125rem;
    $h6-size: 1rem;


    /**
     * Desktop font size
     * Both tablets and desktops
     */

    $h1-size-desktop: 3.375rem;
    $h2-size-desktop: 3rem;
    $h3-size-desktop: 2rem;
    $h4-size-desktop: 1.5rem;
    $h5-size-desktop: 1.125rem;
    $h6-size-desktop: 1rem;

/*------------------------------------*\
      $SPACING
\*------------------------------------*/

  $space-500: $base-spacing-unit * 5; //80px
  $space-400: $base-spacing-unit * 4; //64px
  $space-400: $base-spacing-unit * 3.5; //56px
  $space-300: $base-spacing-unit * 3; //48px
  $space-250: $base-spacing-unit * 2.5; //40px
  $space-200: $base-spacing-unit * 2; //32px
  $space-175: $base-spacing-unit * 2; //28px
  $space-150: $base-spacing-unit * 2; //24px
  $space-125: $base-spacing-unit * 2; //20px
  $space-100: $base-spacing-unit; // 1 rem = 16px
  $space-87-5: $base-spacing-unit * .875; //14px because I know someone will want 14px
  $space-75: $base-spacing-unit * .75; //12px
  $space-50: $base-spacing-unit * .5; //8px
  $space-25: $base-spacing-unit * .25; //4px

/*------------------------------------*\
    $TEXT DIRECTION
\*------------------------------------*/

$dir-default : "left";
$dir-reverse : "right";
$direction   : "LTR";

@if $direction == "RTL" {
    $dir-default : "right";
    $dir-reverse : "left";
}
